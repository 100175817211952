import { AxiosPromise } from 'axios';

import { apiService, apiUrl } from 'services/utils/apiService';
import { getFraudSessionId } from 'utils/localStorageData';

import {
  MerchantSessionDto,
  NewCardTransactionDto,
  NewTokenTransactionDto,
  PaymentConfigDto,
  PaymentMethodDto,
  PaymentMethodRequestDto,
  PaymentMethodUpdateRequestDto,
  TransactionDto,
} from './paymentService.dto';

class PaymentService {
  static BASE_URL = `${apiUrl}/api/payment`;

  fetchClientConfiguration(currencyCode: string): AxiosPromise<PaymentConfigDto> {
    const url = `${PaymentService.BASE_URL}/public/client-configuration`;
    return apiService.get(url, { currencyCode }, {});
  }

  fetchAllPaymentMethods(): AxiosPromise<PaymentMethodDto[]> {
    const url = `${PaymentService.BASE_URL}/payment-methods`;
    return apiService.get(url);
  }

  addPaymentMethod(paymentMethodRequest: PaymentMethodRequestDto): AxiosPromise<PaymentMethodDto> {
    const url = `${PaymentService.BASE_URL}/payment-methods`;
    return apiService.post(url, paymentMethodRequest);
  }

  updatePaymentMethod(id: number, paymentMethodRequest: PaymentMethodUpdateRequestDto): AxiosPromise<PaymentMethodDto> {
    const url = `${PaymentService.BASE_URL}/payment-methods/${id}`;
    return apiService.patch(url, paymentMethodRequest);
  }

  deletePaymentMethod(id: number) {
    const url = `${PaymentService.BASE_URL}/payment-methods/${id}`;
    return apiService.delete(url);
  }

  createCardTransaction(newTransaction: NewCardTransactionDto): AxiosPromise<TransactionDto> {
    const url = `${PaymentService.BASE_URL}/transactions`;
    return apiService.post(url, { ...newTransaction, fraudSessionId: getFraudSessionId() });
  }

  createTokenTransaction(newTransaction: NewTokenTransactionDto): AxiosPromise<TransactionDto> {
    const url = `${PaymentService.BASE_URL}/token-transactions`;
    return apiService.post(url, { ...newTransaction, fraudSessionId: getFraudSessionId() });
  }

  validateMerchant(validationUrl: string): AxiosPromise<MerchantSessionDto> {
    const url = `${PaymentService.BASE_URL}/public/apple-merchants/validation`;
    return apiService.post(url, { validationUrl });
  }
}

const paymentService = new PaymentService();

export { paymentService, PaymentService };
