import { useTranslation } from 'react-i18next';

import { PURCHASE_ACTIONS } from 'assets/constants/purchaseActions';
import RedButton from 'components/buttons/RedButton';
import { useModal } from 'providers/ModalProvider';

import { TrackedItemComponentsProps } from './trackedItemUtils';

export const PayNowButton = ({ isBuyer, order, onClick }: TrackedItemComponentsProps) => {
  const { t } = useTranslation('myProfile');
  const { showGetApp } = useModal();

  const status = order?.status;
  const itemId = order?.itemId;

  const canPayNow = () => {
    return isBuyer && status && PURCHASE_ACTIONS[status].canPayNow;
  };

  const handleOnClick = () => {
    onClick?.();
    showGetApp({ appAreaType: 'ITEM', objectId: itemId, description: t('tracked-item.pay-now') });
  };

  if (!canPayNow()) return null;
  return (
    <RedButton data-testid={'pay-now-btn'} onClick={handleOnClick}>
      {t('tracked-item.pay-now')}
    </RedButton>
  );
};
