import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import PhotoCarousel from 'components/carousel/PhotoCarousel';
import {
  ColumnsContainer,
  Container,
  ContentContainer,
  DetailsColumn,
  FloatingSectionContainer,
  MainColumn,
  PostSomethingAndOwnerContainer,
  Title,
} from 'components/containers/ItemCampaignContainers';
import { ScreenContainer } from 'components/containers/ScreenContainer';
import { LimitedText } from 'components/limitedText/LimitedText';
import { PostSomething } from 'components/postSomething/PostSomething';
import ConversationAwareOwner from 'components/profile/ConversationAwareOwner';
import ActionPanel from 'components/ui-elements/ActionPanel';
import { ClosedInfo } from 'components/ui-elements/ClosedInfo';
import ObjectError from 'components/ui-elements/ObjectError';
import { ObjectNotFound } from 'components/ui-elements/ObjectNotFound';
import Spinner from 'components/ui-elements/Spinner';
import { useCampaignData } from 'services/Campaign/useCampaignData';

import CampaignDetails from './components/CampaignDetails';
import SupportingItems from './components/SupportingItems';

export const CampaignPreview = () => {
  const { campaignId } = useParams();
  const { content: campaign, error, loading } = useCampaignData(Number(campaignId));
  const { t } = useTranslation('campaign');

  const renderErrorOrSpinner = (element: JSX.Element) => {
    return (
      <ScreenContainer>
        <ContentContainer>{element}</ContentContainer>
      </ScreenContainer>
    );
  };

  if (loading) return renderErrorOrSpinner(<Spinner displayLabel={true} />);
  if (error || !campaign) {
    if (error?.response?.status === 410) return renderErrorOrSpinner(<ObjectNotFound />);
    else return renderErrorOrSpinner(<ObjectError error={error} />);
  }

  return (
    <ScreenContainer>
      <Container>
        {campaign.status === 'CLOSED' && <ClosedInfo title={t('campaign-closed')} />}
        <ContentContainer>
          <FloatingSectionContainer>
            <ColumnsContainer>
              <MainColumn>
                <PhotoCarousel photos={campaign.imageUrls} isCampaign={true} />
                <Title data-testid={'campaign-name'}>{campaign.name}</Title>
                <LimitedText description={campaign.description} />
              </MainColumn>
              <DetailsColumn>
                <CampaignDetails campaign={campaign} />
                <ActionPanel appArea={'CAMPAIGN'} objectId={campaign.id} />
              </DetailsColumn>
            </ColumnsContainer>
          </FloatingSectionContainer>
          <SupportingItems campaignId={campaign.id} campaignName={campaign.name} />
          <PostSomethingAndOwnerContainer>
            <PostSomething campaignId={campaign.id} />
            <ConversationAwareOwner userId={campaign.ownerId} context={'CAMPAIGN'} objectId={campaign.id} />
          </PostSomethingAndOwnerContainer>
        </ContentContainer>
      </Container>
    </ScreenContainer>
  );
};
