import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { PaymentButtonProps } from 'features/checkout/social-media/socialMedia.types';

import { preparePaymentRequest } from './paymentRequest';
import { convertPaymentResult } from './paymentResultConverter';
import { useAuthorizationRequest } from './useAuthorizationRequest';
import { useSessionValidationHandler } from './useSessionValidationHandler';

const APPLE_PAY_SESSION_VERSION = 3;
const NATIVE_BUTTON_HTML = '<apple-pay-button buttonstyle="black" type="pay" locale="en"></apple-pay-button>';

const ButtonContainer = styled.div`
  cursor: pointer;
  width: 100%;

  &:hover {
    opacity: 0.8;
  }

  & apple-pay-button {
    --apple-pay-button-width: 100%;
    --apple-pay-button-height: 52px;
    --apple-pay-button-box-sizing: border-box;
    --apple-pay-button-border-radius: 5px;
  }
`;

const Button = styled.div`
  pointer-events: none;
`;

const ApplePayButton = ({ amount, currency, onStateChange, onPaymentAuthorized }: PaymentButtonProps) => {
  const [isVisible, setVisible] = useState(false);
  const { handleMerchantValidation } = useSessionValidationHandler();
  const authorizationRequest = useAuthorizationRequest(amount, currency);

  useEffect(() => {
    const canMakePayments = window.ApplePaySession && ApplePaySession.canMakePayments();
    setVisible(canMakePayments);
    onStateChange(canMakePayments ? 'ready' : 'notAvailable');
  }, []);

  const onRequestApplePay = () => {
    const session = new ApplePaySession(APPLE_PAY_SESSION_VERSION, authorizationRequest);

    session.onvalidatemerchant = async event => {
      const merchantSession = await handleMerchantValidation(event.validationURL);
      merchantSession ? session.completeMerchantValidation(JSON.parse(merchantSession)) : session.abort();
    };

    session.onpaymentauthorized = async event => {
      const paymentRequest = preparePaymentRequest(event.payment);
      const paymentResult = await onPaymentAuthorized(paymentRequest);
      session.completePayment(convertPaymentResult(paymentResult));
    };

    session.begin();
  };

  const renderButton = () => {
    if (isVisible) {
      return (
        <ButtonContainer data-testid='apple-pay-button' onClick={onRequestApplePay}>
          <Button dangerouslySetInnerHTML={{ __html: NATIVE_BUTTON_HTML }} />
        </ButtonContainer>
      );
    } else {
      return <></>;
    }
  };

  return renderButton();
};

export default ApplePayButton;
